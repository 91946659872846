import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useSearchItem } from "./SearchItemProvider";

const CartContext = createContext();

const CartProvider = ({ children }) => {

    // State to hold the cart items
    const [cartItems, setCartItems] = useState([]);
    // convert the following dart code in js code 
    async function insert(d) {
        let localFound = false;
        // const userid = localStorage.getItem('id');

        // if (!d.hasOwnProperty('Item_No')) {
        //     d['Item_No'] = d['Item_No'];
        // }

        cartItems.forEach((element) => {
            // if (element['Item_No'] === d['Item_No'] && element['uid'] === userid) {
            if (element['Item_No'] === d['Item_No']) {
                localFound = true;
            }
        });

        if (localFound) {
            toast.info("Already Exists!");
            return;
        }

        try {
            var { data } = await axios.post(`${url}/api`,
                {
                    "BgmNo": d['Item_No'],
                    "PGrp_Id": "5",
                    "url": "GetBagDetails"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );


            if (!data.response1.length || !data.response2.length) {
                // add amount as 0 to d 
                d['amount'] = 0;
            } 

            data.response2.forEach(item => {
                if (item.bgm_no === data.response1[0].Item_No) {
                    console.log(item); 
                    d['amount'] = item.FSP;
                }
            });
            
        } catch (error) {

        } finally {

        }


        // d['uid'] = userid;
        setCartItems([...cartItems, d]);
        // add to localstorage
        localStorage.setItem('cart', JSON.stringify(cartItems));
        getAll();
        toast.success("Added!");
    }

    async function getAll() {
        // get all from localstorage
        // const userid = localStorage.getItem('id');
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        if (cart.length > 0) {
            cartItems.push(...cart);
        }
    }

    async function deleteItem(stylenumber) {
        // const userid = localStorage.getItem('id');
        const newCartItems = cartItems.filter((item) => item['Item_No'] !== stylenumber); // && item['uid'] === userid);
        setCartItems(newCartItems);
        localStorage.setItem('cart', JSON.stringify(newCartItems));
        toast.success("Deleted!");
    }

    function checkExists(stylenumber) {
        // const userid = localStorage.getItem('id');
        const itemExists = cartItems.some((item) => item['Item_No'] === stylenumber); // && item['uid'] === userid);
        return itemExists;
    }

    const contextValue = useMemo(
        () => ({
            insert,
            deleteItem,
            getAll,
            checkExists,
            cartItems,
        }),
        [cartItems, deleteItem, insert] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
    );
};

export const useCart = () => {
    return useContext(CartContext);
};

export default CartProvider;