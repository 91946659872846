import { Text } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/auth/Login';
import PrivateRoutes from './components/utils/PrivateRoutes';
import AuthProvider from './providers/AuthProvider';
import Dashboard from './components/common/Dashboard';
import TotalSale from './components/TotalSale';
import Products from './components/Products';
import NetSale from './components/NetSale';
import ScanPage from './components/ScanPage';
import CartPage from './components/CartPage';
import InStock from './components/InStock';

function App() {
  return (
    <AuthProvider key={'authprovider'}>
      <Router basename='/'>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/users' element={<Text>Users</Text>} />
            
            <Route path='/total-sale' element={<TotalSale />} />
            <Route path='/net-sale' element={<NetSale />} />
            <Route path='/in-stock' element={<InStock />} />
            
            <Route path='/products' element={<Products />} />
            
            <Route path='/scan' element={<ScanPage />} />
            <Route path='/cart' element={<CartPage />} />

          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
      </Router> 
    </AuthProvider>
  );
}

export default App;