import { SimpleGrid, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { useItemNumberProducts } from '../providers/ItemNumberProductsProvider';
import { Link } from 'react-router-dom';

export default function NetSale() {

    const { data, thisUserNetSaleData } = useItemNumberProducts();

    var valueNotZero = [];

    for (var i of data) {
        if (thisUserNetSaleData
            .filter((element) =>
                element['PCtg_id'].toString() == i['id'].toString())
            .length > 0) {
            valueNotZero.push(i);
        }
    }

    valueNotZero = valueNotZero.sort((a, b) => a['name'].localeCompare(b['name']));

    return (
        <SimpleGrid columns={[4, 4, 4, 8]} spacing='4' p='2' my='2'>
            {valueNotZero.map((value) => (
                <Box
                    as={Link}
                    to={'/products'}
                    state={{
                        id: value['id'],
                        name: value['name'],
                        data: thisUserNetSaleData.filter((element) => element['PCtg_id'].toString() == value['id'].toString()),
                        length: thisUserNetSaleData.filter((element) => element['PCtg_id'].toString() == value['id'].toString()).length,
                    }}
                    aspectRatio={1 / 1}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    // rounded='50%'
                    // p='4'
                    // bg='primary.800'
                    key={value['id']}
                    >
                    <Box
                        p='2'
                        bg='primary.800'
                        rounded='50%'
                        aspectRatio={1/1}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        mb='1'
                    >
                        <Text
                            fontWeight='800'
                            color='#fefefe'
                            fontSize='2xl'
                        >{thisUserNetSaleData
                            .filter((element) =>
                                element['PCtg_id'].toString() == value['id'].toString())
                            .length}
                        </Text>
                    </Box>

                    <Text
                        fontWeight='800'
                        color='primary.800'
                        fontSize='xs'
                        alignItems='center'
                    >{value['name']}</Text>

                </Box>
            ))}
        </SimpleGrid>
    )
}
