import React from 'react'
import { Box, Text, VStack } from '@chakra-ui/react'
import { useItemNumberProducts } from '../providers/ItemNumberProductsProvider';
import { useCart } from '../providers/CartProvider';
import { image } from '../config';
import { FaTrash } from 'react-icons/fa';

export default function CartPage() {

    const { data, thisUserNetSaleData } = useItemNumberProducts();
    const { cartItems, deleteItem } = useCart();

    var valueNotZero = [];

    for (var i of data) {
        if (thisUserNetSaleData
            .filter((element) =>
                element['PCtg_id'].toString() == i['id'].toString())
            .length > 0) {
            valueNotZero.push(i);
        }
    }

    valueNotZero = valueNotZero.sort((a, b) => a['name'].localeCompare(b['name']));

    return (
        <>
            {/* <p>{JSON.stringify(cartItems[0])}</p> */}
            <Box
                overflowX='scroll'
                w={['100%', '100%', '50%', '30%']}
            >
                <table>
                    <thead>
                        <tr>
                            <th>Img</th>
                            <th>
                                <VStack spacing='0'>
                                    <Text spacing='0'>
                                        Style #
                                    </Text>
                                    <Text spacing='0'>
                                        Item #
                                    </Text>
                                </VStack>
                            </th>
                            <th>
                                <VStack spacing='0'>
                                    <Text fontSize='sm'>
                                        Gr wt
                                    </Text>
                                    <Text fontSize='sm'>
                                        Net wt
                                    </Text>
                                </VStack>
                            </th>
                            <th>
                                <VStack spacing='0'>
                                    <Text fontSize='sm'>
                                        Dia wt
                                    </Text>
                                    <Text fontSize='sm'>
                                        Cs wt
                                    </Text>
                                </VStack>
                            </th>
                            <th>
                                <VStack spacing='0'>
                                    <Text fontSize='sm'>
                                        Amount
                                    </Text>
                                    {/* <Text fontSize='sm'>
                                        Qty
                                    </Text> */}
                                </VStack>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems.map((item) => (
                            <tr key={cartItems.indexOf(item)}>
                                <td><img height='100px' src={`${image}/${item.stleNO}.jpg`} /></td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='11px'>
                                            {item.stleNO}
                                        </Text>
                                        <Text fontSize='11px'>
                                            {item.Item_No}
                                        </Text>
                                    </VStack>
                                </td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='11px'>
                                            {item.Product_Weight}
                                        </Text>
                                        <Text fontSize='11px'>
                                            {item.Metal_Weight}
                                        </Text>
                                    </VStack>
                                </td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='11px'>
                                            {item.diawt}
                                        </Text>
                                        <Text fontSize='11px'>
                                            {item.Metal_Weight}
                                        </Text>
                                    </VStack>
                                </td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='sm'>
                                            {item.amount}
                                        </Text>
                                        {/* <Text fontSize='sm'>
                                            {item.fsp}
                                        </Text> */}
                                    </VStack>
                                </td>
                                {/* <td>{JSON.stringify(item.stleNO)}</td> */}
                                <td>
                                    <button onClick={() => deleteItem(item.Item_No)}>
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </>
    )

}
