import React from 'react'
import {
  Card,
  Container,
  Text,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react'
import { useItemNumberProducts } from '../../providers/ItemNumberProductsProvider'
import { Link } from 'react-router-dom';

export default function Dashboard() {

  const { thisUserNetSaleData, thisUserData, thisUserInStockData, loading } = useItemNumberProducts();

  if (loading) {
    return <VStack>
      <div>Loading...</div>
    </VStack>
  }

  return (
    <Container p='4' maxW='4xl'>
      <VStack width='100%' spacing={4}>

        <SimpleGrid columns={[2, 2, 4, 4]} spacing='4' w='100%'>

          <Card as={Link} to={'/total-sale'} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{thisUserData.length}</Text>
            <Text>Total Sale</Text>
          </Card>

          <Card as={Link} to={'/net-sale'} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{thisUserNetSaleData.length}</Text>
            <Text>Net Sale</Text>
          </Card>

          <Card justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>0</Text>
            <Text>Monthly Sale</Text>
          </Card>

          <Card as={Link} to={'/in-stock'} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{thisUserInStockData.length}</Text>
            <Text>In Stock</Text>
          </Card>

        </SimpleGrid>

      </VStack>
    </Container>
  )
}   
