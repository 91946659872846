import {
    SimpleGrid,
    Text,
    Box,
    HStack,
    VStack,
    IconButton,
    Icon
} from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom';
import { image } from '../config';
import { Link } from 'react-router-dom';
import { FiCheck, FiPlus, FiShoppingCart, FiTrash } from 'react-icons/fi';
import { useCart } from '../providers/CartProvider';

export default function Products() {

    const { state } = useLocation();
    const { insert, checkExists, deleteItem } = useCart();

    return (
        <>
            {/* <div>{JSON.stringify(state)}</div> */}

            <SimpleGrid columns={[3, 3, 6, 6]} w='100%' p='3' spacing='2'>
                {state.data.map((value) => (
                    <Box
                        // as={Link}
                        // to={`/scan?item=${value['Item_No']}`}
                        // aspectRatio={1 / 1}
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-end'
                        alignItems='center'
                        p='4'
                        key={value['id']}
                    >
                        <Box
                            as={Link}
                            to={`/scan?item=${value['Item_No']}`}
                            // aspectRatio={1 / 1}
                            w='100%'
                            h='100%'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            mb='1'
                        >
                            <img src={`${image}/${value['stleNO']}.jpg`} />
                        </Box>
                        <HStack>
                            <VStack spacing='1'>
                                <Text fontSize='xs'>{value['Item_No']}</Text>
                                <Text fontSize='sm'>{value['fsp'] == null ? "" : `Rs ${Math.round(value['fsp'])}`}</Text>

                                {checkExists(value['Item_No']) ? (
                                    <IconButton>
                                        <Icon
                                            as={FiTrash}
                                            color='red.800'
                                            size='20px'
                                            onClick={() => deleteItem(value['Item_No'])}
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        variant='outline'
                                        onClick={() => insert(value)}
                                    >
                                        <Icon
                                            as={FiShoppingCart}
                                            color='green.800'
                                            size='20px'
                                        />
                                    </IconButton>
                                )}
                            </VStack>

                        </HStack>
                        <HStack w='100%' bg='red'>
                        </HStack>
                    </Box>
                ))}
            </SimpleGrid >
        </>
    )
}
